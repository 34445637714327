import { computed, defineComponent } from 'vue';
import { useHead } from '@vueuse/head';
// import HomeBanner from './components/Banner.vue';
import HomeBanner from './components/FixedBanner.vue';
import HomeIntro from './components/Intro.vue';
import HomeNews from './components/News.vue';
import HomeNewsSkeleton from './components/NewsSkeleton.vue';
// import HomePartner from './components/Partner.vue';
// import HomeSolution from './components/Solution.vue';
import { FEATURE_OPTIONS } from './config';
// import DEFAULT_BANNER_IMG from './images/banner_default.png';
import { mergeHomeApi, LOG_MERGE_HOME_API } from '@/base/api/merge';
// import {LOG_API_PATHS} from '@/base/api/common';
import useStoreRequest from '@/base/hooks/useStoreRequest';
// import {handleUrl} from '@/base/utils/url';
import FeedbackButton from '@/components/Feedback/index.vue';
import TLayout from '@/components/Layout/Layout.vue';
import { HomeMutationEnum } from '@/store/home';
import Joinus from '@/views/Home/components/Joinus.vue';
import { HOME_SEO_CONFIG } from '@/base/config/seo';
import { useStore } from '@/store';
import { apmLog } from '@/base/hooks/useApm/apmLog';
/* eslint-disable-next-line */
export default defineComponent({
  name: 'Home',
  components: {
    TLayout,
    HomeBanner,
    HomeIntro,
    HomeNews,
    // HomePartner,
    Joinus,
    FeedbackButton,
    // HomeSolution,
    HomeNewsSkeleton
  },
  setup() {
    apmLog({
      expReqUrl: [LOG_MERGE_HOME_API]
    });
    useHead(HOME_SEO_CONFIG);
    // if (!IS_NODE) {
    //     const log = useLog();
    //     log.send('trade_home_pv');
    // }
    const store = useStore();
    const {
      state
    } = useStoreRequest({
      space: 'home',
      mutationName: HomeMutationEnum.SET_MERGE_HOME_DATA,
      stateKey: 'mergeHomeData'
    }, mergeHomeApi, {
      routers: ['banner', 'partner', 'news']
    });
    // const news = computed(() => state.data?.news);
    const news = computed(() => {
      var _state$value$data;
      return (_state$value$data = state.value.data) === null || _state$value$data === void 0 ? void 0 : _state$value$data.news;
    });
    // banner暂不用后端返回数据，暂时写死
    // const remoteBanners = computed(() => state.value.data?.banner || []);
    // 避免 cls、必须加载一张图片
    // const banner = computed(() => {
    //     return remoteBanners.value.length
    //         ? remoteBanners.value
    //         : [
    //             {
    //                 img_url: DEFAULT_BANNER_IMG as string,
    //                 jump_url: handleUrl('solution/common'),
    //             },
    //         ];
    // });
    const isSpider = computed(() => {
      return store.state.spiderInfo.isSpider;
    });
    return {
      FEATURE_OPTIONS,
      state,
      // banner,
      news,
      isSpider
    };
  }
});