import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-207f1d48"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "section"
};
const _hoisted_2 = {
  class: "section_title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("section", _hoisted_1, [_createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1), _renderSlot(_ctx.$slots, "default", {}, undefined, true)]);
}