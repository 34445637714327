import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { HOME_NEWS_OPTIONS } from '../config';
import HomeSection from './Section.vue';
import { handleUrl } from '@/base/utils/url';
import { NEWS_ENUM } from '@/base/api/interface/home';
export default defineComponent({
  name: 'HomeNews',
  components: {
    HomeSection
  },
  props: {
    news: {
      type: Object,
      default: undefined
    }
  },
  setup(props) {
    const router = useRouter();
    const state = computed(() => {
      var _props$news, _props$news2;
      return {
        trends: ((_props$news = props.news) === null || _props$news === void 0 || (_props$news = _props$news[NEWS_ENUM.trends]) === null || _props$news === void 0 ? void 0 : _props$news.slice(0, 4)) || [],
        market: ((_props$news2 = props.news) === null || _props$news2 === void 0 || (_props$news2 = _props$news2[NEWS_ENUM.market]) === null || _props$news2 === void 0 ? void 0 : _props$news2.slice(0, 4)) || []
      };
    });
    return {
      state,
      HOME_NEWS_OPTIONS,
      router,
      handleUrl
    };
  }
});