import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-82d43abc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "home-title"
};
const _hoisted_2 = {
  class: "feature"
};
const _hoisted_3 = {
  class: "feature-list"
};
const _hoisted_4 = {
  class: "img"
};
const _hoisted_5 = ["src", "alt"];
const _hoisted_6 = {
  class: "title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_home_banner = _resolveComponent("home-banner");
  const _component_home_intro = _resolveComponent("home-intro");
  const _component_home_news_skeleton = _resolveComponent("home-news-skeleton");
  const _component_home_news = _resolveComponent("home-news");
  const _component_el_skeleton = _resolveComponent("el-skeleton");
  const _component_joinus = _resolveComponent("joinus");
  const _component_t_layout = _resolveComponent("t-layout");
  const _component_feedback_button = _resolveComponent("feedback-button");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_t_layout, {
    class: "home"
  }, {
    default: _withCtx(() => [_createVNode(_component_home_banner), _ctx.isSpider ? (_openBlock(), _createElementBlock("h1", _hoisted_1, "出海易 - 首页")) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_2, [_createElementVNode("ul", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.FEATURE_OPTIONS, item => {
      return _openBlock(), _createElementBlock("li", {
        key: `${item}`,
        class: "feature-item"
      }, [_createElementVNode("p", _hoisted_4, [_createElementVNode("img", {
        src: item.img_url,
        alt: item.title
      }, null, 8, _hoisted_5)]), _createElementVNode("h4", _hoisted_6, _toDisplayString(item.title), 1)]);
    }), 128))])]), _createVNode(_component_home_intro), _createVNode(_component_el_skeleton, {
      loading: _ctx.state.isLoading
    }, {
      template: _withCtx(() => [_createVNode(_component_home_news_skeleton)]),
      default: _withCtx(() => [_createVNode(_component_home_news, {
        news: _ctx.news
      }, null, 8, ["news"])]),
      _: 1
    }, 8, ["loading"]), _createVNode(_component_joinus)]),
    _: 1
  }), _createVNode(_component_feedback_button)], 64);
}