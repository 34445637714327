import { defineComponent } from 'vue';
/* eslint-disable-next-line */
export default defineComponent({
  name: 'THomeSection',
  props: {
    title: {
      type: String,
      required: true
    }
  }
});