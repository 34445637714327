import { defineComponent } from 'vue';
import { HOME_INTRO_OPTIONS } from '../config';
/* eslint-disable-next-line */
export default defineComponent({
  name: 'HomeIntro',
  setup() {
    return {
      intro: HOME_INTRO_OPTIONS
    };
  }
});