import featureImg1 from './images/feature-index_1.png';
import featureImg2 from './images/feature-index_2.png';
import featureImg3 from './images/feature-index_3.png';
import featureImg4 from './images/feature-index_4.png';
import featureImg5 from './images/feature-index_5.png';
import featureImg6 from './images/feature-index_6.png';
import featureIntroImg1 from './images/feature-intro_1.png';
import featureIntroImg2 from './images/feature-intro_2.png';
import featureIntroImg3 from './images/feature-intro_3.png';
import newsImg1 from './images/news-newest@2x.png';
import newsImg2 from './images/news-market@2x.png';
import newsImg3 from './images/news-trends@2x.png';
import partnerImg1 from './images/partner-google@2x.png';
import partnerImg2 from './images/partner-facebook@2x.png';
import partnerImg3 from './images/partner-youtube@2x.png';
import partnerImg4 from './images/partner-linkedin@2x.png';
import partnerImg5 from './images/partner-semrush@2x.png';

export const HOME_INTRO_OPTIONS = [
    {
        title: '营销服务',
        subTitle: '全球视角、数据智能',
        img_url: featureIntroImg1,
        desc: [
            '数据算法、二十余载能力沉淀',
            '专注B2B、更懂买家采购行为',
            '国际合作、深耕全球企业数据',
            '品牌背书、多维数据智能呈现',
        ],
        link: {
            value: '预约一对一咨询',
            url: '/#joinus',
        },
    },
    {
        title: '建站服务',
        subTitle: '出海利器、掌控先机',
        img_url: featureIntroImg2,
        desc: [
            '商情工具、知己知彼先人一步',
            '专业建站、深度优化数据沉淀',
            '邮件营销、海外通路高效拓客',
            '私域管理、客户分层精准触达',
        ],
        link: {
            value: '预约一对一咨询',
            url: '/#joinus',
        },
    },
    {
        title: '数据服务',
        subTitle: '精准投放、品牌孵化',
        img_url: featureIntroImg3,
        desc: [
            '全球合作、覆盖更多广告平台',
            '线上线下、整合更全获客渠道',
            '本地服务、助力中企品牌出海',
            '智能护航、保障效果跑赢大盘',
        ],
        link: {
            value: '预约一对一咨询',
            url: '/#joinus',
        },
    },
    // {
    //     title: '网络服务',
    //     subTitle: '跨境电商唯快不破',
    //     img: '',
    //     desc: ['全球多地部署', '高性能页面渲染引擎', '全球CDN加速', '了解更多网络服务'],
    //     link: {
    //         value: '点击了解更多网络服务',
    //         url: '/#joinus',
    //     },
    // },
];

export const HOME_NEWS_OPTIONS = [
    {
        key: 'market',
        title: '外贸动态',
        img_url: newsImg1,
        imgTitle: '最新资讯',
        index: 0,
    },
    {
        key: 'market',
        title: '外贸动态',
        img_url: newsImg2,
        imgTitle: '外贸动态',
        index: 1,
    },
    {
        key: 'trends',
        title: '营销干货',
        img_url: newsImg3,
        imgTitle: '营销干货',
        index: 0,
    },
];

export const HOME_PARTNER_OPTIONS = [
    {
        img_url: partnerImg1,
        name: 'Google',
    },
    {
        img_url: partnerImg2,
        name: 'facebook',
    },
    {
        img_url: partnerImg3,
        name: 'YouTube',
    },
    {
        img_url: partnerImg4,
        name: 'Linkedin',
    },
    {
        img_url: partnerImg5,
        name: 'SEMRUSH',
    },
];

export const FEATURE_OPTIONS = [
    {
        title: '全球视角',
        img_url: featureImg1,
    },
    {
        title: '数据智能',
        img_url: featureImg2,
    },
    {
        title: '出海利器',
        img_url: featureImg3,
    },
    {
        title: '掌握先机',
        img_url: featureImg4,
    },
    {
        title: '精准投放',
        img_url: featureImg5,
    },
    {
        title: '品牌孵化',
        img_url: featureImg6,
    },
];