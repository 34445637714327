import { defineComponent } from 'vue';
import DEFAULT_BANNER_IMG from '../images/banner_default.png';
// import {TRIAL_TYPE_ENUM} from '@/views/components/Freetrial/types';
// import {INJECT_KEY__TO_TRIAL} from '@/views/components/Freetrial/injectKeys';
export default defineComponent({
  setup: () => {
    // 试用
    // const toTrial = inject(INJECT_KEY__TO_TRIAL);
    // function handleClick() {
    //     scLog('click', {
    //         elem: 'home-trial-banner-click',
    //     });
    //     toTrial && toTrial({type: TRIAL_TYPE_ENUM.trial});
    // }
    return {
      DEFAULT_BANNER_IMG
      // handleClick,
    };
  }
});