import { defineComponent } from 'vue';
import JoinusForm from '@/views/components/Joinus/JoinusForm.vue';
const config = {
  joinUsTitle: '企业出海服务的未来在这里',
  joinUsContent: '出海易的角色不仅仅是出海企业的一站式跨境服务商，更是企业长期发展的伙伴和顾问，我们会在您使用出海易服务的过程中提供全面协助，为您企业跨境出海之旅保驾护航。'
};
export default defineComponent({
  name: 'Joinus',
  components: {
    JoinusForm
  },
  setup() {
    const {
      joinUsTitle,
      joinUsContent
    } = config;
    return {
      joinUsTitle,
      joinUsContent
    };
  }
});